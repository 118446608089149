import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import './patient-history-page';

@customElement('create-patient-page')
export class CreatePatientPage extends LitElement {
  static styles = css`
    /* Add styles for the form */
    .container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Arial, sans-serif;
    }
    input {
      display: block;
      width: 30%;
      padding: 10px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    button {
      display: block;
      width: 30%;
      padding: 10px;
      margin-bottom: 15px;
      background-color: #2196f3;
      color: white;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  `;

  render() {
    return html`
      <div class="container">
        <h2>Create Patient:</h2>
        <input id="name" type="text" placeholder="Patient Name" />
        <input id="surname" type="text" placeholder="Patient Surname" />
        <input id="idp" type="text" placeholder="Patient SA ID number" maxlength="13" @change=${this.validateID}/>
        <input id="dob" type="date" placeholder="Patient DOB" />
        <input id="medAidName" type="text" placeholder="MedicalAid or private" />
        <input id="medAidOP" type="text" placeholder="MedicalAid option or N/A" />
        <input id="medAidNum" type="text" placeholder="MedicalAid Number or N/A" />

        <button @click=${this.goToHistoryPage}>Continue to history</button>
        <button @click=${this.goBack}>Back</button>
        <button class="button" @click=${this.logout}>Logout</button>
      </div>
    `;
  }

  validateID() {
    // Remove any non-digit characters
    const idpi = this.shadowRoot?.getElementById('idp')as HTMLInputElement;


    idpi.value = idpi.value.replace(/\D/g, '');

    // Ensure it doesn't exceed 13 digits
    
}

  async goToHistoryPage() {
    const idpi = this.shadowRoot?.getElementById('idp')as HTMLInputElement;
    const namei = this.shadowRoot?.getElementById('name')as HTMLInputElement;
    const surnamei = this.shadowRoot?.getElementById('surname')as HTMLInputElement;
    const dobi = this.shadowRoot?.getElementById('dob')as HTMLInputElement;
    const medAidNamei = this.shadowRoot?.getElementById('medAidName')as HTMLInputElement;
    const medAidNumi = this.shadowRoot?.getElementById('medAidNum')as HTMLInputElement;
    const medAidOPi = this.shadowRoot?.getElementById('medAidOP')as HTMLInputElement;

    const idp = idpi?.value;
    const name = namei?.value;
    const surname = surnamei?.value;
    const dob = dobi?.value;
    const medAidName = medAidNamei?.value;
    const medAidNum = medAidNumi?.value;
    const medAidOP = medAidOPi?.value;
    sessionStorage.setItem('patiendob', dob);
    const nurseid = sessionStorage.getItem('userId');

    const patientData = { nurseid,idp, name, surname, dob, medAidName, medAidNum, medAidOP };

    try {
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/create-patient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(patientData),
      });

      const data = await response.json();

      if (response.ok) {
        sessionStorage.setItem('lastPage', 'create-patient-page');
        console.log("Patient created successfully:", data);

        const createHistory = document.createElement('patient-history-page');
        this.replaceWith(createHistory);
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert(`Failed to create patient: ${error}`);
    }
  }

  goBack() {

      const lastPageElement = document.createElement("patient-options-page");
      this.replaceWith(lastPageElement);

  }

  async logout() {
    try {
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/logout', {
        method: 'POST',
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);

        const LOGIN = document.createElement('auth-page');
        this.replaceWith(LOGIN);
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert(`Logout failed: ${error}`);
    }
  }
}
