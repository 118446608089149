// wound-photo-page.ts
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import './diagnostic-page';


@customElement('wound-photo-page')
export class WoundPhotoPage extends LitElement {
  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }

    .images-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin-bottom: 16px;
    }

    .image-placeholder {
      width: 100px;
      height: 100px;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
    }

    .wound-location {
      width: 80%;
      height: 150px;
      background-color: #f0f0f0;
      margin-bottom: 16px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: crosshair;
    }

    .red-circle {
      width: 20px;
      height: 20px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
    }

    button {
      width: 80%;
      padding: 12px;
      background-color: #1e90ff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 16px;
      font-size: 16px;
    }

    button:hover {
      background-color: #005cbf;
    }
  `;

  markedCoordinates: { x: number; y: number }[] = [];

  render() {
    return html`
      <div class="container">
        <h2>Previous 4 images for comparison if exist</h2>
        <div class="images-grid">
          <div class="image-placeholder"></div>
          <div class="image-placeholder"></div>
          <div class="image-placeholder"></div>
          <div class="image-placeholder"></div>
        </div>
        <div class="wound-location" @click="${this._markWound}">
          Body image of wound location
          ${this.markedCoordinates.map((coords) =>
            html`<div class="red-circle" style="left:${coords.x}px; top:${coords.y}px;"></div>`)}
        </div>
        <button @click="${this._takePhoto}">Take photo</button>
        <button @click="${this._continue}">Continue</button>
        <button @click="${this._goBack}">Back</button>
      </div>
    `;
  }

  _markWound(event: MouseEvent) {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left - 10; // Adjust for circle radius
    const y = event.clientY - rect.top - 10;

    this.markedCoordinates = [...this.markedCoordinates, { x, y }];
    this.requestUpdate(); // Ensure the component re-renders






  }

  _takePhoto() {
    const videoElement = document.createElement('video');
    const canvasElement = document.createElement('canvas');
    const context = canvasElement.getContext('2d');

    // Button to capture the photo
    const takePhotoButton = document.createElement('button');
    takePhotoButton.innerText = 'Take Photo';
    takePhotoButton.style.position = 'fixed';
    takePhotoButton.style.bottom = '20px';
    takePhotoButton.style.left = '50%';
    takePhotoButton.style.transform = 'translateX(-50%)';
    takePhotoButton.style.padding = '10px 20px';
    takePhotoButton.style.fontSize = '18px';
    takePhotoButton.style.zIndex = '1001';
    takePhotoButton.style.color = 'white';
    takePhotoButton.style.backgroundColor = '#1e90ff'; // On top of the video

    // Buttons for retake and use
    const retakeButton = document.createElement('button');
    retakeButton.innerText = 'Retake';
    retakeButton.style.display = 'none'; // Initially hidden
    retakeButton.style.position = 'fixed';
    retakeButton.style.bottom = '60px';
    retakeButton.style.left = '5%';
    retakeButton.style.padding = '10px 20px';
    retakeButton.style.fontSize = '18px';
    retakeButton.style.zIndex = '1001';
    retakeButton.style.backgroundColor = '#1e90ff';
    retakeButton.style.color = 'white';

    const useButton = document.createElement('button');
    useButton.innerText = 'Use Photo';
    useButton.style.display = 'none'; // Initially hidden
    useButton.style.position = 'fixed';
    useButton.style.bottom = '60px';
    useButton.style.right = '5%';
    useButton.style.color = 'white';
    useButton.style.padding = '10px 20px';
    useButton.style.fontSize = '18px';
    useButton.style.zIndex = '1001';
    useButton.style.backgroundColor = '#1e90ff';

    // Loading screen
    const loadingScreen = document.createElement('div');
    loadingScreen.innerText = 'Saving photo...';
    loadingScreen.style.display = 'none'; // Initially hidden
    loadingScreen.style.color = 'white';
    loadingScreen.style.position = 'fixed';
    loadingScreen.style.top = '50%';
    loadingScreen.style.left = '50%';
    loadingScreen.style.transform = 'translate(-50%, -50%)';
    loadingScreen.style.fontSize = '24px';
    loadingScreen.style.fontWeight = 'bold';
    loadingScreen.style.zIndex = '1002';
    document.body.appendChild(loadingScreen);

    // Apply full-screen styles to the video element
    videoElement.style.position = 'fixed';
    videoElement.style.top = '0';
    videoElement.style.left = '0';
    videoElement.style.width = '100vw';  // Full viewport width
    videoElement.style.height = '100vh'; // Full viewport height
    videoElement.style.zIndex = '1000';  // Ensure it appears on top
    videoElement.style.objectFit = 'cover'; // Ensure the video covers the screen

    // Function to start the camera with environment (back) first
    const startCamera = async () => {
        let constraints = {
            video: {
                facingMode: { exact: 'environment' } // Try to access the back camera
            }
        };

        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            videoElement.srcObject = stream;
            videoElement.play();

            // Display the video and capture button
            document.body.appendChild(videoElement);
            document.body.appendChild(takePhotoButton);

            // Handle photo capture
            takePhotoButton.addEventListener('click', () => {
                // Set canvas size to video size
                canvasElement.width = videoElement.videoWidth;
                canvasElement.height = videoElement.videoHeight;

                // Draw the current video frame onto the canvas
                context?.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

                // Hide video and show the captured photo
                videoElement.style.display = 'none';
                takePhotoButton.style.display = 'none';
                document.body.appendChild(canvasElement); // Show captured photo
                canvasElement.style.position = 'fixed';
                canvasElement.style.top = '0';
                canvasElement.style.left = '0';
                canvasElement.style.width = '100vw';
                canvasElement.style.height = '100vh';
                canvasElement.style.zIndex = '1000';

                // Show retake and use buttons
                retakeButton.style.display = 'block';
                useButton.style.display = 'block';
                document.body.appendChild(retakeButton);
                document.body.appendChild(useButton);
            });

            // Handle retake photo
            retakeButton.addEventListener('click', () => {
                // Reset canvas and hide it
                document.body.removeChild(canvasElement); // Remove the canvas element

                // Show the video feed and take photo button again
                videoElement.style.display = 'block';
                retakeButton.style.display = 'none';  // Hide retake and use buttons
                useButton.style.display = 'none';
                takePhotoButton.style.display = 'block'; // Show capture button again
            });

            // Handle use photo
            useButton.addEventListener('click', () => {
                // Show loading screen
                loadingScreen.style.display = 'block';

                // Convert the canvas content to a base64 string
                const photoDataUrl = canvasElement.toDataURL('image/png');

                // Send the base64 string to the back-end
                fetch('https://058d81c6-3000.inc1.devtunnels.ms/upload-photo', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ imageData: photoDataUrl })
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Photo saved at:', data.filePath);

                    // Hide loading screen
                    loadingScreen.style.display = 'none';

                    // Stop the video stream and remove the video element
                    stream.getTracks().forEach(track => track.stop());
                    document.body.removeChild(videoElement);
                    document.body.removeChild(canvasElement);
                    document.body.removeChild(retakeButton);
                    document.body.removeChild(useButton);
                })
                .catch(error => {
                    console.error('Error uploading the photo:', error);
                    loadingScreen.innerText = 'Error saving photo. Please try again.'; // Update loading screen text on error
                });
            });
        } catch (error) {
            console.error('Error accessing the back camera:', error);

            // Fallback to front camera
            constraints.video = { facingMode: { exact: 'user' } }; // Try to access the front camera
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                videoElement.srcObject = stream;
                videoElement.play();

                // Display the video and capture button
                document.body.appendChild(videoElement);
                document.body.appendChild(takePhotoButton);

                // Handle photo capture and other event listeners can remain the same
                // Re-using existing event listeners for takePhotoButton, retakeButton, and useButton
                // Handle photo capture
                takePhotoButton.addEventListener('click', () => {
                    // Set canvas size to video size
                    canvasElement.width = videoElement.videoWidth;
                    canvasElement.height = videoElement.videoHeight;

                    // Draw the current video frame onto the canvas
                    context?.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

                    // Hide video and show the captured photo
                    videoElement.style.display = 'none';
                    takePhotoButton.style.display = 'none';
                    document.body.appendChild(canvasElement); // Show captured photo
                    canvasElement.style.position = 'fixed';
                    canvasElement.style.top = '0';
                    canvasElement.style.left = '0';
                    canvasElement.style.width = '100vw';
                    canvasElement.style.height = '100vh';
                    canvasElement.style.zIndex = '1000';

                    // Show retake and use buttons
                    retakeButton.style.display = 'block';
                    useButton.style.display = 'block';
                    document.body.appendChild(retakeButton);
                    document.body.appendChild(useButton);
                });

                // Handle retake photo
                retakeButton.addEventListener('click', () => {
                    // Reset canvas and hide it
                    document.body.removeChild(canvasElement); // Remove the canvas element

                    // Show the video feed and take photo button again
                    videoElement.style.display = 'block';
                    retakeButton.style.display = 'none';  // Hide retake and use buttons
                    useButton.style.display = 'none';
                    takePhotoButton.style.display = 'block'; // Show capture button again
                });

                // Handle use photo
                useButton.addEventListener('click', () => {
                    // Show loading screen
                    loadingScreen.style.display = 'block';

                    // Convert the canvas content to a base64 string
                    const photoDataUrl = canvasElement.toDataURL('image/png');

                    // Send the base64 string to the back-end
                    fetch('https://058d81c6-3000.inc1.devtunnels.ms/upload-photo', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ imageData: photoDataUrl })
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Photo saved at:', data.filePath);

                        // Hide loading screen
                        loadingScreen.style.display = 'none';

                        // Stop the video stream and remove the video element
                        stream.getTracks().forEach(track => track.stop());
                        document.body.removeChild(videoElement);
                        document.body.removeChild(canvasElement);
                        document.body.removeChild(retakeButton);
                        document.body.removeChild(useButton);
                    })
                    .catch(error => {
                        console.error('Error uploading the photo:', error);
                        loadingScreen.innerText = 'Error saving photo. Please try again.'; // Update loading screen text on error
                    });
                });
            } catch (error) {
                console.error('Error accessing the front camera:', error);
            }
        }
    };

    // Start the camera
    startCamera();
}


  _continue() {
    sessionStorage.setItem('lastPage','wound-photo-page');
    const diagnosepage = document.createElement('diagnostic-page');
    this.replaceWith(diagnosepage);
    console.log("Continuing to the diagnose page.");
  }

  _goBack() {
      const lastPage = sessionStorage.getItem('lastPage');
if (lastPage) {
    const lastPageElement = document.createElement(lastPage);
    this.replaceWith(lastPageElement);
}
}
}
